<template>
    <base-popup
            :popup-model="show"
            :title="formTitle"
            :valid="!valid"
            :width="40"
            @onCancelButtonClick="closePopupHandler"
            @onSaveButtonClick="saveItemHandler"
    >
        <v-form
                :ref="formRef"
                v-model="valid"
                lazy-validation
        >
            <v-card-text>
                <v-text-field
                        outlined
                        dense
                        color="deep-purple accent-4"
                        hide-details="auto"
                        type="text"
                        :rules="requiredFieldRule"
                        v-model="currentItem.wpUsername"
                        label="WP Username"
                />
                <br>
                <v-text-field
                        outlined
                        dense
                        color="deep-purple accent-4"
                        hide-details="auto"
                        type="password"
                        v-model="currentItem.wpPassword"
                        label="WP Password"
                />
            </v-card-text>
        </v-form>
    </base-popup>
</template>

<script>
    import BasePopup from "./BasePopup";
    import Popup from "../mixins/Popup";
    export default {
        name: "WPAccessPopup",
        mixins: [Popup],
        components: {BasePopup},
        computed: {
            formTitle() {
                return this.isEditMode
                    ? 'WP Access'
                    : 'WP Access'
            }
        }
    }
</script>

<style scoped>

</style>